const templateOptions = [
  {
    id: 'onyx',
    name: 'Onyx',
  },
  {
    id: 'gengar',
    name: 'Gengar',
  },
  {
    id: 'castform',
    name: 'Castform',
  },
  {
    id: 'glalie',
    name: 'Glalie',
  },
  {
    id: 'celebi',
    name: 'Celebi',
  },
  // {
  //   id: 'orientalent',
  //   name: 'Orientalent',
  // },
];

export default templateOptions;
