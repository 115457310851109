import { Link } from 'gatsby';
import { isFunction } from 'lodash';
import React, { memo } from 'react';
import Logo from '../../shared/Logo';
import * as styles from './LeftNavbar.module.css';
import SectionIcon from '../../shared/SectionIcon';
import sections from '../../../data/leftSections';

const LeftNavbar = () => (
  <div className={styles.container}>
    <div>
      <Link to="/">
        <Logo size="60px" />
      </Link>
    </div>

    <hr className="my-6" />

    <div className="grid grid-cols-1 gap-4 text-primary-500">
      {sections.map((x) =>
        !x.sectionVisible ||
        (x.sectionVisible && isFunction(x.sectionVisible)) ? (
          <SectionIcon
            key={x.id}
            section={x}
            containerId="LeftSidebar"
            tooltipPlacement="right"
          />
        ) : null,
      )}
    </div>

    <hr className="mt-auto my-6" />
  </div>
);

export default memo(LeftNavbar);
