import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';

const Comments = ({ id }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Heading id={id} />

      <Input
        type="textarea"
        label={t('shared.forms.comments')}
        path="comments.body"
      />
    </section>
  );
};

export default memo(Comments);
